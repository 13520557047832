.card {
  color: $primary-purple;

  /deep/ .content {
    text-align: center;
    padding: 65px 140px 46px;
    font-size: 20px;

    span {
      font-weight: bold;
    }

    p {
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 1.88;
    }
  }
}

@media (max-width: 768px) {
  .card /deep/ .content {
    padding: 40px 24px;
  }
}

@media (max-width: 550px) {
  .card {
    /deep/ .header {
      padding: 0;
    }

    /deep/ .content {
      padding: 40px 24px;
      line-height: 20px;
      text-align: left;

      p {
        font-size: 16px;
      }
      .form_button {
        text-align: center;
      }
    }
  }
}
