#block {
  .top {
    display: grid;
    grid-template-columns: 80% 20%;
    .left {
      .title {
        color: #333333;
        font-size: 18px;
        margin-bottom: 10px;

        i {
          font-style: normal;
        }

        .i_error {
          color: #df1d14;
        }

        .i_success {
          color: #4200eb;
        }
      }

      .detail /deep/ {
        div {
          font-size: 16px;
          font-weight: 200;
          line-height: 1.56;
          letter-spacing: normal;
          color: $primary-purple;
          margin-bottom: 16px;
        }

        ul {
          padding-left: 18px;

          li {
            list-style: disc;
            font-size: 16px;
            font-weight: 200;
            line-height: 1.56;
            color: $primary-purple;
            margin-bottom: 12px;
          }
        }
      }
    }

    .right {
      /deep/ .upload_demo .button_upload {
        border-radius: 23px;
        line-height: normal;
        border: solid 1px $primary-purple;
        color: $primary-purple;
        background-color: unset;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        display: -webkit-flex; /* Safari */
        align-items: center; /* Safari */
      }
    }
  }
}

@media (max-width: 550px) {
  #block .top .left .title {
    font-size: 16px !important;
  }
  #block .top .left .detail /deep/ ul li {
    font-size: 14px;
  }
}
