#UploadIdOrAddress {
  /deep/ .el-dialog__wrapper .el-dialog {
    max-width: 1200px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 43px 32px 48px;

      .top {
        .title {
          font-size: 24px;
          font-weight: 600;
          line-height: 1.42;
          color: $primary-purple;
        }

        .tip {
          font-size: 16px;
          font-weight: 200;
          line-height: 1.56;
          color: $primary-purple;
          margin-top: 24px;
          margin-bottom: 40px;
        }
      }

      .container {
        padding: 40px 40px 20px;
        border: $container-border;
        border-radius: $container-radius;
        margin-bottom: 40px;
      }

      .id_form {
        padding: 30px 30px 0;
        margin: 40px 0 0;

        border: $container-border;
        border-radius: $container-radius;

        .title {
          font-weight: 200;
          color: $primary-purple;
          margin-bottom: 25px;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
        }
      }

      .errMsg {
        color: red;
        margin-bottom: 10px;
      }

      .btn_box {
        line-height: 1.5;
        text-align: center;
      }
    }
  }
}

#success {
  /deep/ .el-dialog__body {
    word-break: normal;
    padding: 20px 0 43px;
    text-align: center;

    img {
      margin-bottom: 25px;
      width: 114px;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
      text-align: center;
      color: $primary-purple;
    }
  }
}

@media (max-width: 660px) {
  #UploadIdOrAddress {
    /deep/ .el-dialog__wrapper .el-dialog .el-dialog__body {
      .top {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        .title {
          font-size: 20px;
        }
        .tip {
          margin-top: 32px;
          margin-bottom: 32px;
        }
      }

      .container {
        padding: 20px;
        border: 1px solid $primary-purple;
        border-radius: 10px;
      }

      .id_form {
        border: 1px solid $primary-purple !important;
        border-radius: 10px !important;
      }

      .upload {
        text-align: right;
      }
    }
  }

  .title {
    font-size: 14px;
  }
}
